import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ClientService from '../services/ClientService';
import EthereumGatewayService from '../services/EthereumGatewayService';
import { Client } from '../types/Client';
import { EthereumAddress, EthereumAddresses, EthereumAddressWithClient } from '../types/EthereumAddresses';
import { EthereumStandaloneTransferRequest } from '../types/EthereumTransfer';
import { getFullName } from '../utils';
import { AppThunk, RootState } from './index';
import { showAlert } from './notificationSlice';

interface EthereumGatewayState {
    addresses: EthereumAddresses | null;
    clients: Client[];
    selectedAddress: EthereumAddress | null;
    isTransferLoading: boolean;
    transactionId: string | null;
}

const initialState: EthereumGatewayState = {
    addresses: null,
    clients: [],
    selectedAddress: null,
    isTransferLoading: false,
    transactionId: null,
};

const ethereumWalletsSlice = createSlice({
    name: 'ethereumGateway',
    initialState,
    reducers: {
        updateEthereumBalances: (state, action: PayloadAction<EthereumAddresses>) => {
            state.addresses = action.payload;
        },
        clearEthereumBalances: (state) => {
            state.addresses = null;
            state.clients = [];
        },
        updateClients: (state, action: PayloadAction<Client[]>) => {
            state.clients = action.payload;
        },
        openEthereumTransferPopUp: (state, action: PayloadAction<EthereumAddress>) => {
            state.selectedAddress = action.payload;
        },
        closeEthereumTransferPopUp: (state) => {
            state.selectedAddress = null;
            state.transactionId = null;
        },
        setTransferLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransferLoading = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string>) => {
            state.transactionId = action.payload;
        },
    },
});

export const {
    updateEthereumBalances,
    clearEthereumBalances,
    updateClients,
    openEthereumTransferPopUp,
    closeEthereumTransferPopUp,
    setTransferLoading,
    setTransactionId,
} = ethereumWalletsSlice.actions;

export const fetchEthereumBalances = (): AppThunk => dispatch =>
    EthereumGatewayService.listBalances()
        .then(async lst => {
            dispatch(updateEthereumBalances(lst));

            const promises: Promise<Client>[] = lst
                .map(x => x.clientId)
                .filter(x => !!x)
                .map(x => ClientService.getClient(x!));

            const res = await Promise.allSettled(promises);
            const clients = res
                .filter(x => x.status === 'fulfilled')
                .map(y => (y as PromiseFulfilledResult<Client>).value);

            dispatch(updateClients(clients));
        });

export const makeEthereumTransfer = (req: EthereumStandaloneTransferRequest): AppThunk => dispatch => {
    dispatch(setTransferLoading(true));
    EthereumGatewayService.transfer(req)
        .then(x => {
            dispatch(setTransactionId(x.txId));
            dispatch(showAlert('Transfer created'));
        })
        .finally(() => {
            dispatch(setTransferLoading(false));
        });
};

export const getEthereumAddresses = (state: RootState): EthereumAddresses | null => state.ethereumWalletsSlice.addresses;
export const getEthereumAddressClients = (state: RootState): Client[] => state.ethereumWalletsSlice.clients;
export const getSelectedEthereumAddress = (state: RootState): EthereumAddress | null => state.ethereumWalletsSlice.selectedAddress;
export const getEthereumTransferIsLoading = (state: RootState): boolean => state.ethereumWalletsSlice.isTransferLoading;
export const getEthereumTransactionId = (state: RootState): string | null => state.ethereumWalletsSlice.transactionId;


export const getEthereumAddressesWithClients = (state: RootState): EthereumAddressWithClient[] => {
    const { addresses, clients } = state.ethereumWalletsSlice;
    if(!addresses)
        return [];

    const findClient = (id: string | undefined): Client | undefined => clients.find(x => x.id === id);

    return addresses.map(address => ({
        ...address,
        client: findClient(address.clientId)
    }))
}

export const findEthereumWalletName = (balance: EthereumAddress | EthereumAddressWithClient | null, clients: Client[] = []): string => {
    if (!balance)
        return '';

    if (!balance.clientId)
        return 'Main';

    const client = (balance as EthereumAddressWithClient).client || clients.find(x => balance.clientId === x.id);
    return getFullName(client);
};

export default ethereumWalletsSlice.reducer;
